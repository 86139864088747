import { motion } from "framer-motion"
import { graphql, PageProps } from "gatsby"
import { getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../component/layout/layout"
import {
  citationSection,
  citationStyle,
  dropbackImage,
  heroImageContainer,
} from "../styles/index.css"
import { theme } from "../theme.css"
import { Expo } from "../types"

type DataType = {
  heroImage: {
    id: string
    text: string
    url: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  lastExpo: {
    edges: { node: Expo }[]
  }
  citation: {
    id: string
    content: {
      content: string
    }
  }
}

export default function Homepage({
  data: { heroImage, citation },
}: PageProps<DataType>) {
  const gatsHeroImage = getImage(heroImage.url.gatsbyImageData)

  return (
    <Layout>
      <div className={heroImageContainer}>
        <StaticImage
          src="../images/20221102_121104.jpg"
          alt="exposition"
          style={{ height: "100%", width: "100%" }}
        />
        {/* <video
          loop
          autoPlay
          muted
          style={{
            objectFit: "cover",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          <source src={PainterVideo} type="video/mp4" />
        </video> */}
        <div className={dropbackImage}></div>
      </div>
      <div className={citationSection}>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            duration: 1,
          }}
          className={citationStyle}
        >
          <p>
            Bonjour, Je suis heureux de pouvoir vous accueillir sur mon site.
            J'espère que vous passerez un excellent moment en consultant mes
            différents espaces.
          </p>
          <br />
          <p>Si vous désirez de me contacter, voici mes coordonnées:</p>

          <a
            style={{ color: theme.colors.primary }}
            target="_blank"
            href="mailto:patrick.clichy@orange.fr?subject = Demande d'informations&body = Message"
          >
            patrick.clichy@orange.fr
          </a>
          <p> +33 06 14 80 88 32</p>
        </motion.p>
        {/* <div>
          <h3 className={subtitle}>Prochaine Exposition</h3>
          <ExpoThumbnail data={latestExpo} />
        </div> */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    heroImage: contentfulHeroImage {
      id
      text
      url {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 50
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    citation: contentfulCitation {
      id
      content {
        content
      }
    }
  }
`
